<template>
  <div class="filter-item">
    <div class="form-label device-type">
      <div class="text">
        设备分类：
        <div class="device-category">
          <Classification @changeType="handleChangeType" />
        </div>
      </div>
    </div>
    <div class="form-label device-type">
      <div>
        <div class="showTypes">
          <div class="device-category">
            <Classification @changeType="handleChangeType" />
          </div>

          <span @click="clearTypes" class="pick-all"> 全部 </span>
        </div>
      </div>

      <div
        class="deviceTypeName"
        v-for="[typeId, typeName] in TypeMaps.slice(0, 3)"
        :key="typeId"
        @click="handlePickType(typeName, typeId)">
        {{ typeName }}
      </div>
    </div>
  </div>
</template>

<script>
  import Classification from '@/components/classification'
  export default {
    props: ['handleChangeType', 'TypeMaps', 'handlePickType','clearTypes'],
    components: {
      Classification,
    },
  }
</script>

<style scoped lang="scss">
  @import '@/assets/css/cssValue';

  .device-category-main {
    position: absolute;
    left: 0;
    top: 0;
  }
  .device-type {
    display: flex;
    gap: 10px;
  }
  .showTypes {
    position: relative;
    &:hover {
      color: $hover;
    }
    &:hover .device-category {
      display: flex;
    }
  }
  .device-category {
    &:hover {
      display: block;
    }
    display: none;
    position: absolute;
    left: 1rem;
    top: -2rem;
  }

  .type-tags {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  .all {
    padding: 5px;
  }
  .tag {
    position: relative;
    top: 5px;
  }
  .device-types {
    display: flex;
    .text {
      color: $hover;
      margin-left: 1rem;
    }
  }
  .deviceTypeName {
    &:hover {
      color: $hover;
      cursor: pointer;
    }
  }
  .pick-all:hover {
    cursor: pointer;
  }
</style>
