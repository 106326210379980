<template>
  <div class="filter-item">
    <div class="form-label">地区：</div>
    <cityPicker :handleCityChange="handleCityChange" :cityVal="cityVal"/>
  </div>
</template>

<script>
  import cityPicker from '@/components/filterForm/regionPicker/cityPicker'
  export default {
    props: ['handleCityChange','cityVal'],
    components: {
      cityPicker,
    },
  }
</script>

<style></style>
