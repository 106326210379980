<template>
  <div class="filter-item">
    <div class="form-label">当前筛选：</div>
    <div v-if="currentFilteredType">
      <span class="filter-tag">
        {{ currentFilteredType }}
        <div>
          <i class="el-icon-close" @click="reselect"></i>
        </div>
      </span>
      <span class="btn-reselect" @click="reselect"><i class="el-icon-refresh"></i>重新选择</span>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['currentFilteredType', 'reselect'],
  }
</script>

<style scoped lang="scss">
  .search-device .current-filter .filter-tag {
    padding: 6px 10px;
    border-radius: 5px;
  }
  ::v-deep .el-icon-close:hover {
    cursor: pointer;
  }
</style>
