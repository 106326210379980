<template>
  <section class="search">
    <div class="dropdown">
      <el-dropdown placement="bottom" trigger="click" @command="handleCommand">
        <span class="el-dropdown-link">
          {{ commandValue }}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item :command="'找设备'">找设备</el-dropdown-item>
          <el-dropdown-item :command="'找需求'">找需求</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <input type="text" :placeholder="searchValue ?? '请输入关键字进行搜索'" class="input" v-model="searchValue" />
    <div>
      <div class="btn-search" @click="handleSearch">搜索</div>
    </div>
  </section>
</template>

<script>
  export default {
    props: ['commandValue','searchValue'],
    emits: ['searchDevice', 'searchNeed'],
    methods: {
      handleCommand(command) {
        if (command !== this.commandValue) {
          this.commandValue = command
        }
      },
      handleSearch() {
        console.log(`${this.commandValue} event happened`)
        console.log(`searchValue is ${this.searchValue}`)
        if (this.commandValue === '找设备') {
          this.$emit('searchDevice', this.searchValue)
        } else {
          this.$emit('searchNeed', this.searchValue)
        }
      },
    },
  }
</script>

<style scoped lang="scss">
  @import '@/assets/css/cssValue';

  .search {
    display: flex;
    align-items: center;
    border: 2px solid #ff992b;
    height: 50px;
    background-color: #fff;
    .dropdown .el-dropdown-link {
      font-size: 18px;
      color: #323232;
      cursor: pointer;
      padding: 10px 24px 10px 24px;
      background-color: #f5f5f5;
    }
    .dropdown .el-icon-arrow-down {
      font-size: 14px;
    }
    .input {
      height: 46px;
      border-radius: 0;
      border: 0;
      flex: 1;
      padding: 0 16px;
      font-size: 18px;
      color: #323232;
    }
    .btn-search {
      height: 46px;
      padding: 0 56px;
      display: inline-flex;
      align-items: center;
      background-color: #ff992b;
      transition: 0.3s;
      cursor: pointer;
      font-size: 18px;
      color: #fff;
      font-weight: bold;
    }
    .btn-search:hover {
      background-color: $hover;
    }
    .btn-search:active {
      background-color: $active;
    }
  }
</style>
