<template>
  <div>
    <div class="city" @mouseenter="handleOpenCityPopup">
      <div class="city-name"><i class="f-icon icon-location"></i>{{ cityVal }}</div>
      <CityPopup
        :isOpen="isOpen"
        @clickCity="handleClickCity"
        @mouseleave.native="handleCloseCityPopup" />
    </div>
  </div>
</template>
<script>
  import CityPopup from './cityPopup'
  export default {
    props: ['handleCityChange','cityVal'],
    components: {
      CityPopup,
    },
    data() {
      return {
        isOpen: false,
        // cityVal: '未选择',
      }
    },
    methods: {
      handleCloseCityPopup() {
        this.isOpen = false
      },
      handleOpenCityPopup() {
        this.isOpen = true
      },
      handleClickCity(selectedCity) {
        console.log('selectedCity: ', selectedCity)
        // this.cityVal = selectedCity
        this.handleCloseCityPopup()
        this.handleCityChange(selectedCity)
      },
    },
  }
</script>
<style lang="scss" scoped>
  @import '@/assets/css/cssValue';

  .city {
    margin-right: 30px;
    .city-name {
      background: url(~@/assets/images/header_city_arrow.png) right center no-repeat;
      background-size: 8rpx 4rpx;
      display: inline-flex;
      align-items: center;
      padding: 8px 16px 8px 0;
      cursor: pointer;
      transition: 0.3s;
    }
  }
</style>
