<template>
  <div class="city-popup" v-if="isOpen">
    <div class="city-popup-main">
      <div class="province">
        <span
          class="province-item"
          @click="handleClickCity(item)"
          v-for="(item, index) in bigCity"
          :key="index"
          >{{ item }}</span
        >
      </div>
      <div class="letter">
        <span
          v-for="(letter, index) in a"
          :key="index"
          @click="handleSwitchRegionTableByLetter(letter)"
          >{{ letter }}</span
        >
      </div>
      <div class="city-list">
        <div class="city-item" v-for="item in cityList" :key="item.area_id">
          <span class="label" v-if="item.children != null">{{ item.area_name }}:</span>
          <div class="content">
            <span
              @click="handleClickCity(item2.area_name)"
              v-for="item2 in item.children"
              :key="item2.area_id"
              >{{ item2.area_name }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { getRegionList } from '@/api/home.js'
  import kits from '@/kits'
  export default {
    props: ['isOpen'],
    emits: ['clickCity'],
    data() {
      return {
        msgnum: 0,
        kits: kits,
        a: ['A', 'F', 'G', 'H', 'J', 'L', 'N', 'Q', 'S', 'T', 'X', 'Y', 'Z'],
        bigCity: ['全部', '北京', '上海', '广州', '深圳', '南京', '天津'],
        regionList: [],
        cityList: [],
        cityVal: '默认地区',
      }
    },
    methods: {
      handleSwitchRegionTableByLetter(letter) {
        let cityLists = []
        this.regionList.province.map((item) => {
          if (item.first_char == letter) {
            cityLists.push(item)
          }
        })
        this.cityList = cityLists
      },
      handleClickCity(city) {
        this.cityVal = city
        this.$emit('clickCity', city)
      },
    },
    mounted() {
      getRegionList()
        .then((res) => {
          if (res.status != 200) return
          this.regionList = res.data
          this.regionList.province.forEach((item) => {
            this.regionList.city.forEach((item2) => {
              if (item2.parent_id == item.area_id) {
                if (item.children == null) {
                  item.children = []
                }
                // 将市字去掉
                item2.area_name = item2.area_name.substring(0, 2)
                item.children.push(item2)
              }
            })
          })
          this.cityList = this.regionList.province
        })
        .catch((err) => {
          // 出错时要做的事情
          return err
        })
    },
  }
</script>
<style lang="scss" scoped>
  @import '@/assets/css/cssValue';

  .city-popup {
    position: absolute;
    z-index: 95;
  }
  .city-popup-main {
    position: relative;
    left: -20px;
    top: -30px;
    box-shadow: 0 1px 3px 0 gray;
    border: 2px solid white;
    border-radius: 10px;
    width: 60vw;
    // color: $color6;
    padding-bottom: 14px;
    background-color: rgba(255, 255, 255, 0.96);
    height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .province {
    display: flex;
    padding: 28px 0 12px;
    border-bottom: 1px solid #666;
  }
  .province .province-item {
    padding: 10px 30px;
    cursor: pointer;
    transition: 0.3s;
  }
  .province .province-item:hover {
    color: $primary;
  }
  .letter {
    margin-top: 20px;
    height: 31px;
    background-color: #f9f4f4;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
    // color: #f9f4f4;
  }
  .letter span {
    padding: 4px 10px;
    cursor: pointer;
    transition: 0.3s;
    font-weight: bold;
  }
  .letter span:hover {
    color: $primary;
  }
  .letter span.selected {
    color: $primary;
  }
  .city-list {
    margin-top: 30px;
  }
  .city-list .city-item {
    display: flex;
    padding: 0 28px;
    margin-bottom: 20px;
  }
  .city-list .city-item .label {
    font-size: 14px;
    // color: $color6;
    font-weight: bold;
    min-width: 66px;
    line-height: 27px;
  }
  .city-list .city-item .content {
    display: flex;
    flex-wrap: wrap;
  }
  .city-list .city-item .content span {
    height: 27px;
    border-radius: 2px;
    font-size: 14px;
    padding: 0 11px;
    line-height: 27px;
    margin-right: 17px;
    cursor: pointer;
    transition: 0.3s;
    margin-bottom: 10px;
  }
  .city-list .city-item .content span:hover {
    color: $primary;
  }
  .city-list .city-item .content span.selected {
    background-color: #ff992b;
    color: #fff;
  }
  .city:hover {
    color: $primary;
  }
  .btn-text {
    cursor: pointer;
    transition: 0.3s;
    padding: 8px 0;
  }
  .btn-text:hover {
    color: $primary;
  }
  .btn-login {
    margin-right: 14px;
    padding: 8px 0;
    cursor: pointer;
    transition: 0.3s;
  }
  .btn-login:hover {
    color: $primary;
  }
  .btn-group {
    display: inline-flex;
    align-items: center;
    margin-right: 22px;
  }
  .btn-group .btn-item {
    cursor: pointer;
    transition: 0.3s;
    padding: 8px 0;
  }
  .btn-group .btn-item:hover {
    color: $primary;
  }
  .btn-group .divider {
    display: inline-block;
    width: 1px;
    height: 12px;
    vertical-align: middle;
    background-color: #727171;
    margin: 0 21px;
  }
  .msg {
    height: 36px;
    display: inline-flex;
    align-items: center;
    background-color: #525151;
    padding: 0 21px 0 24px;
  }
  .msg .num {
    height: 20px;
    min-width: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #ff3c00;
    color: #fff;
    border-radius: 50%;
    margin-left: 5px;
  }
  .user-info {
    min-width: 230px;
    margin-right: 23px;
    position: relative;
    z-index: 89;
    .user-info-popup {
      position: absolute;
      top: 36px;
      left: 0;
      right: 0;
      z-index: 3;
      background-color: #525151;
      border-radius: 0 0 6px 6px;
      padding: 14px 12px;
      display: none;
      min-width: 200px;
      .content {
        flex: 1;
      }
      .avatar {
        width: 48px;
        height: 48px;
        border: 3px solid #dcdcdc;
        border-radius: 50%;
        margin-right: 9px;
      }
      .tag {
        min-width: 50px;
        text-align: center;
        margin-right: 5px;
        font-size: 12px;
        background-color: #ff992b;
        padding: 1px 5px;
        border-radius: 2px;
        color: #fff;
      }
      .account {
        font-size: 14px;
        color: #b5b5b6;
        margin-top: 4px;
      }
      .btn-quit {
        min-width: 50px;
        cursor: pointer;
        transition: 0.3s;
      }
      .btn-quit:hover {
        color: $primary;
      }
    }
  }
  .user-info:hover .user-info-popup {
    display: block;
  }
  .user-info-name {
    min-width: 200px;
    background: url(~@/assets/images/header_city_arrow.png) right 11px center no-repeat;
    background-size: 8rpx 4rpx;
    padding: 8px 24px 8px 14px;
    cursor: pointer;
    .tag {
      font-size: 12px;
      background-color: #ff992b;
      padding: 1px 5px;
      border-radius: 2px;
      color: $color6;
      margin-left: 10px;
      margin-right: 4px;
    }
  }
  .user-info:hover .user-info-name {
    background-color: #525151;
  }
</style>
