<template>
  <div class="pagination">
    <el-pagination
      layout="prev, pager, next"
      :currentPage="currentPage"
      :pageSize="pageSize"
      :pageCount="pageCount"
      @next-click="nextPage"
      @prev-click="prevPage"
      @current-change="currentChange">
    </el-pagination>
  </div>
</template>

<script>
  export default {
    props: ['currentPage', 'pageSize', 'pageCount'],
    emits: ['nextClick', 'prevClick','currentChange'],
    methods: {
      nextPage() {
        console.log('nextClick')
        this.$emit('nextClick')
      },
      prevPage() {
        console.log('prevPage')
        this.$emit('prevClick')
      },
      currentChange(currentPage) {
        console.log(currentPage)
        console.log('currentChange')
        this.$emit('currentChange',currentPage)
      },
    },
  }
</script>

<style scoped lang="scss">
  .el-pagination {
    display: flex;
    justify-content: center;
  }
</style>
