import request from './request.js'

//轮播图
export function getBannerList(data) {
  return request({
    url: '/web/home/banner/list',
    method: 'post',
    data,
  })
}
/* 三级菜单 */
export function getClassifyList(data) {
  return request({
    url: '/web/home/type/list',
    method: 'post',
    data,
  })
}
/* 产品信息 */
